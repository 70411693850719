import React from "react";
import { ReactComponent as IconBlind } from "./icons/blind.svg";
import { ReactComponent as IconCalendar } from "./icons/calendar.svg";
import { ReactComponent as IconCard } from "./icons/card.svg";
import { ReactComponent as IconCardBlocked } from "./icons/card-blocked.svg";
import { ReactComponent as IconCardBlockedTitle } from "./icons/card-blocked-title.svg";
import { ReactComponent as IconChat } from "./icons/chat.svg";
import { ReactComponent as IconChevronRight } from "./icons/chevron-right.svg";
import { ReactComponent as IconChevronLeft } from "./icons/chevron-left.svg";
import { ReactComponent as IconChevronBottom } from "./icons/chevron-bottom.svg";
import { ReactComponent as IconHome } from "./icons/home.svg";
import { ReactComponent as IconLockCard } from "./icons/lock-card.svg";
import { ReactComponent as IconMoney } from "./icons/money.svg";
import { ReactComponent as IconPaper } from "./icons/paper.svg";
import { ReactComponent as IconPaperOrange } from "./icons/paper-orange.svg";
import { ReactComponent as IconPesquisarFatura } from "./icons/pesquisar-fatura.svg";
import { ReactComponent as IconBloquearCartao } from "./icons/bloquear-cartao.svg";
import { ReactComponent as IconPassword } from "./icons/password.svg";
import { ReactComponent as IconPasswordProfile } from "./icons/password-profile.svg";
import { ReactComponent as IconQuestion } from "./icons/question.svg";
import { ReactComponent as IconTick } from "./icons/tick.svg";
import { ReactComponent as IconTransfer } from "./icons/transfer.svg";
import { ReactComponent as IconTransition } from "./icons/transition.svg";
import { ReactComponent as IconUser } from "./icons/user.svg";
import { ReactComponent as Warning } from "./icons/warning.svg";
import { ReactComponent as Seguranca } from "./icons/Seguranca.svg";
import { ReactComponent as Consulta } from "./icons/Consulta.svg";
import { ReactComponent as ConsultGrey } from "./icons/consult-grey.svg";
import { ReactComponent as Document } from "./icons/document.svg";
import { ReactComponent as IconPlus } from "./icons/plus-icon.svg";
import { ReactComponent as IconMinus } from "./icons/minus-icon.svg";
import { ReactComponent as Check } from "./icons/check.svg";
import { ReactComponent as Waiting } from "./icons/waiting.svg";
import { ReactComponent as OpenEye } from "./icons/openEye.svg";
import { ReactComponent as Information } from "./icons/information.svg";
import { ReactComponent as AlertCircle } from "./icons/alert-circle.svg";
import { ReactComponent as RotateCircle } from "./icons/rotate-circle.svg";
import { ReactComponent as CloseCircle } from "./icons/close-circle.svg";

// Baixar Fatura
import { ReactComponent as TracoCurvo } from "./icons/baixarFatura/tracoCurvo.svg";
import { ReactComponent as SetaViradoParaBaixo } from "./icons/baixarFatura/setaViradoParaBaixo.svg";

// Carteira Digital
import { ReactComponent as Ajuda } from "./icons/carteiraDigital/ajuda.svg";

// Banners
import { ReactComponent as Banner2Web } from "./images/banners/Banner2Web.svg";

import { ReactComponent as Lending } from "./icons/emprestimo/emprestimo.svg";

import { ReactComponent as AllCards } from "./icons/allCards.svg";

import { ReactComponent as IconWhatsApp } from "./icons/whats-app-icon.svg"

const icons = {
  eye: require("./icons/eye.png"),
  blind: <IconBlind />,
  calendar: <IconCalendar />,
  card: <IconCard />,
  cardBlocked: <IconCardBlocked />,
  cardBlockedtitle: <IconCardBlockedTitle />,
  chat: <IconChat />,
  whatsApp: <IconWhatsApp />,
  chevronRight: <IconChevronRight />,
  chevronLeft: <IconChevronLeft />,
  chevronBottom: <IconChevronBottom />,
  home: <IconHome />,
  lockCard: <IconLockCard />,
  money: <IconMoney />,
  paper: <IconPaper />,
  paperOrange: <IconPaperOrange />,
  pesquisarFatura: <IconPesquisarFatura />,
  bloquearCartao: <IconBloquearCartao />,
  password: <IconPassword />,
  passwordProfile: <IconPasswordProfile />,
  question: <IconQuestion />,
  tick: <IconTick />,
  transfer: <IconTransfer />,
  transition: <IconTransition />,
  user: <IconUser />,
  warning: <Warning />,
  seguranca: <Seguranca />,
  consulta: <Consulta />,
  consultGrey: <ConsultGrey />,
  document: <Document />,
  tracoCurvo: <TracoCurvo />,
  setaViradoParaBaixo: <SetaViradoParaBaixo />,
  plusIcon: <IconPlus />,
  minusIcon: <IconMinus />,
  ajuda: <Ajuda />,
  check: <Check />,
  waiting: <Waiting />,
  openEye: <OpenEye />,
  banner2Web: <Banner2Web />,
  information: <Information />,
  alert: <AlertCircle />,
  rotate: <RotateCircle />,
  close: <CloseCircle />,
  lending: <Lending />,
  allCards: <AllCards />,
};

const images = {
  logo: require("./images/logo.png"),
  logoHorizontal: require("./images/logo-horizontal.png"),
  logoHorizontalTeuCard: require("./images/logo-horizontal-teucard.png"),
  logoTeuCard: require("./images/logo-teucard.png"),
  logoTeuCardBranco: require("./images/logo-teucard-branco.png"),
  logoTeuCred: require("./images/logo2-teuCred.png"),
  logoTeuCred2: require("./images/logo3-teuCred.png"),
  logoTeuCredTransparent: require("./images/teucred-logo-transparent.png"),
  logoTeuCashWhite: require("./images/logo-teucash-white.png"),
  emojisLogin: require("./images/emojis-login.png"),
  defaultCard: require("./images/default-card.png"),
  cardBackground: require("./images/cardBackground.png"),
  cardBackgroundTeuCard: require("./images/cardBackground-teucard.png"),
  creditCardLogoBlue: require("./images/blue-card.png"),
  creditCardLogoRose: require("./images/rose-card.png"),
  creditCardLogoWhite: require("./images/white-card.png"),
  cardLogo: require("./images/cardLogo.png"),
  avatar: require("./images/avatar.png"),
  warning: require("./images/warning.png"),
  visible: require("./images/visible.png"),
  invisible: require("./images/invisible.png"),
  arrowDown: require("./images/arrow-down.png"),
  search: require("./images/search.png"),
  banner_card: require("./images/banners/banners_card.png"),
  logoCarteiraDigital: require("./images/logo-carteira-digital.png"),
  teuCardBlack: require("./images/teuCardBlack.png"),
  pesquisarFatura: require("./images/icone-pesquisar-fatura.png"),
  bloquearCartao: require("./images/bloquear-cartao.png"),
  creditCardBlack: require("./images/credit-card.png"),
  imageUser: require("./images/perfilUser.png"),
  notificationBell: require("./images/notification-img.png"),
  orangeCircle: require("./images/orange_circle.png"),
  chevronLeft: require("./images/chevron-left.png"),
  successValidation: require("./images/success-validation.png"),
  erroValidation: require("./images/erro-validation.png"),
  rgFront: require("./images/rg-front.png"),
  rgBack: require("./images/rg-back.png"),
  selfieCam: require("./images/selfie-cam.png"),
  selfieBlack: require("./images/selfie-black.png"),
  cameraSelfieDesktop: require("./images/cameraSelfieMaskDesktop.png"),
  selfieDesktop: require("./images/selfieDesktop.png"),
  creditCardBackGround: require("./images/crediCardBackGround.png"),
  semCartao: require("./images/semCartao.png"),
  linkViewBackground: require("./images/linkViewBackground.png"),
  eyeHome: require("./images/eye-home.png"),
  blindHome: require("./images/blind-home.png"),
  searchProposal: require("./images/searchProposal.png"),
  checkGreen: require("./images/check-green.png"),

  //Icones Bandeiras
  visa: require("./images/bandeiras/logo-visa.png"),
  mastercard: require("./images/bandeiras/logo-mastercard.png"),
  elo: require("./images/bandeiras/logo-elo.png"),
  elo2: require("./images/bandeiras/logo-elo2.png"),
  amex: require("./images/bandeiras/logo-amex.png"),
  amex2: require("./images/bandeiras/logo-amex2.png"),
  diners: require("./images/bandeiras/logo-diners.png"),
  diners2: require("./images/bandeiras/logo-diners2.png"),
  hipercard: require("./images/bandeiras/logo-hipercard.png"),
  defaultCard: require("./images/bandeiras/logo-default.png"),
  banner2Web: require("./images/banners/banner02.png"),

  parcela: require("./icons/emprestimo/tipo-parcela.svg"),
  limite: require("./icons/emprestimo/tipo-limite.svg"),
  saque: require("./icons/emprestimo/tipo-saque.svg"),
  creditCardLending: require("./icons/emprestimo/credit_card_lending.svg"),
  teuCardLending: require("./icons/emprestimo/teucard_lending.svg"),
  warningLending: require("./images/warning_lending.png"),
  selfieLending: require("./icons/selfie.svg"),
  checkOrange: require("./icons/check-orange.svg"),
  star: require("./images/star.png"),
  ornament: require("./images/ornament.png"),
  infoLogoEmprestimo: require("./images/infoLogoEmprestimo.png"),
  companysEC: require("./images/companysEC.png"),
  loanSearch: require("./images/loanSearch.png"),
  onboardingWalletNoAuth: require("./images/onBoardingWalletNoAuth.jpeg"),
  allCards: require("./icons/allCards.svg"),
};

const fonts = {
  marineRoundedBlack: require("./fonts/MarineRounded-Black.otf"),
  marineRoundedBold: require("./fonts/MarineRounded-Bold.otf"),
  marineRoundedRegular: require("./fonts/MarineRounded-Regular.otf"),
};

export default { icons, images, fonts };
