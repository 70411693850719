import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import Button, { BUTTON_TYPES } from "~/components/Button";
import Input from "~/components/Input";
import ModalOneButton from "~/components/Modals/OneButtonPopup";
import {
  verificationCodeMask,
  verificationCodeUnmask,
  phoneMask,
  hidePhone,
} from "~/utils/mask";
import { CustomizedError } from "~/utils/errors";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import themes from "~/themes";
import * as S from "../styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import RadioButtonGroup from "~/components/RadioButtonGroup";
import Dialog from "~/components/Modals/Dialog";

const ValidadeCode = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [modalIsOpenFeed, setModalIsOpenFeed] = useState(false);
  const [modalText, setModalText] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const { state } = useLocation();
  const [count, setCount] = useState(2);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceBase64, setInvoiceBase64] = useState("");
  const [statusError, setStatusError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [sendType, setSendType] = useState();

  const GETNoAuthInvoice = useApi(
    Endpoints.cliente.getNoAuthInvoice
      .replace("PARAM_CPF", state?.cpf)
      .replace("PARAM_TIPO_ENVIO", sendType),
    ApiMethod.GET
  );

  useEffect(() => {
    if (chronometer > 0) {
      const interval = setInterval(() => {
        setChronometer(chronometer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [chronometer, state.phone]);

  useEffect(() => {
    if (invoiceBase64) {
      const byteCharacters = atob(invoiceBase64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      setPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [invoiceBase64]);

  const handleDisabledButton = () => verificationCode.length !== 7;

  const handleClickButton = async () => {
    try {
      sessionStorage.setItem(
        "@NovaEra:xCode",
        verificationCodeUnmask(verificationCode)
      );
      await getInvoicePdf();
    } catch (err) {
      setModalText(err.mensagem);
    }
  };

  const getNoAuthInvoice = async () => {
    const response = await GETNoAuthInvoice.callApi();
    const data = response.data;

    if (response.status >= 200 && response.status < 300) {
      if (data.celular) {
        sessionStorage.setItem(
          "@NovaEra:payloadFaturaNoAuth",
          JSON.stringify({
            ano: data.anoReferencia,
            mes: data.mesReferencia,
            cpf: state?.cpf,
            validaEmail: sendType === "EMAIL",
          })
        );

        // Mostra a modal de reenvio de SMS ou EMAIL
        if (count >= 2) {
          setModalText(`${sendType} reenviado com sucesso!`);
          setModalIsOpenFeed(true);
        }

        setChronometer(60);
      }
    } else {
      setModalText(data.mensagem);
      setModalIsOpenFeed(true);
    }
    setCount((prev) => prev + 1);
  };

  const RequestPostFaturaGeradaNoAuth = useApi(
    Endpoints.relatorio.postFaturaGeradaNoAuth,
    ApiMethod.POST
  );

  const PostFaturaGeradaNoAuth = async () => {
    const aux = sessionStorage.getItem("@NovaEra:payloadFaturaNoAuth");
    const payload = JSON.parse(aux);

    const request = await RequestPostFaturaGeradaNoAuth.callApi(payload);

    if (request.status >= 200 && request.status < 300) {
      return { status: request.status, data: request.data };
    } else {
      sessionStorage.removeItem("@NovaEra:xCode");
      throw CustomizedError({ message: request.data.mensagem });
    }
  };

  async function getInvoicePdf() {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const response = await PostFaturaGeradaNoAuth();

      if (response.status >= 200 && response.status < 300) {
        setChronometer(0);
        setInvoiceBase64(response.data.documentFatura);
        setModalText("");
        setIsLoading(false);
        setStatusError(false);
      }
    } catch (error) {
      setModalIsOpenFeed(true);
      setModalText(error.message ? error.message : "Nenhuma fatura encontrada");
      setErrorMessage(
        error.message ? error.message : "Nenhuma fatura encontrada"
      );
      setIsLoading(false);
      setStatusError(true);
    }
  }
  const reSendSMS = () => {
    if (chronometer === 0) {
      getNoAuthInvoice();
    }
  };
  const expandScreen = () => {
    const binary = atob(invoiceBase64.replace(/\s/g, ""));
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    return window.open(fileURL, "_blank");
  };

  const downloadInvoice = () => {
    const binary = atob(invoiceBase64.replace(/\s/g, ""));
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileURL;
    link.download = "fatura.pdf"; 
    document.body.appendChild(link);
    link.click();

  
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL); 
  };

  const handleChange = (selectedIndex) => {
    console.log("Opção selecionada:", selectedIndex);

    if (selectedIndex === 0) {
      setSendType("SMS");
    } else {
      setSendType("EMAIL");
    }
  };

  const textModal = (
    <S.TextModal>
      <p>
        Por favor, escolha abaixo qual das opções devemos enviar o código de
        validação:{" "}
      </p>
      <RadioButtonGroup
        options={[
          {
            index: 0,
            title: `Por sms para o celular cadastrado ${hidePhone(
              phoneMask(state?.phone)
            )}`,
          },
          { index: 1, title: `Para o email cadastrado ${state?.email}` },
        ]}
        name="sendType"
        onChange={handleChange}
      />
    </S.TextModal>
  );

  return (
    <Layout auth={false}>
      <Dialog
        // icon={themes.components.images.warning}
        positiveLabel="Continuar"
        open={modalIsOpen}
        message={textModal}
        positiveAction={() => {
          console.log("Click!");
          setModalIsOpen(false);
          getNoAuthInvoice();
        }}
        buttonDisabledPositive={sendType ? false : true}
      />
      <Dialog
        icon={themes.components.images.warning}
        positiveLabel="Ok"
        open={modalIsOpenFeed}
        message={modalText}
        positiveAction={() => {
          setModalIsOpenFeed(false);
          setVerificationCode("");
        }}
      />
      <S.Content>
        <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
        <p>
          <strong>Bem-vindo</strong> ao Fatura TeuCard
        </p>
        {RequestPostFaturaGeradaNoAuth.loading ||
          modalIsOpen ||
          GETNoAuthInvoice.loading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <>
            {!!invoiceBase64 ? (
              <>
                {!errorMessage ? (
                  <iframe
                    src={pdfUrl}
                    type="application/pdf"
                    title="PDF Viewer"
                    style={{
                      border: "1px solid #d3d3d3",
                    }}
                  />
                ) : (
                  <h6>{errorMessage}</h6>
                )}
                <S.ContentButton>
                  {!isLoading && !statusError && (
                    <>
                    <Button
                      onClick={() => expandScreen()}
                      type={BUTTON_TYPES.outlined}
                      style={{ marginBottom: '8px' }}
                    >
                      Expandir fatura
                    </Button>
                    <Button
                      onClick={() => downloadInvoice()}
                      type={BUTTON_TYPES.outlined}
                    >
                      Download da fatura
                    </Button>
                  </>
                  
                  )}
                </S.ContentButton>
              </>
            ) : (
              <S.Main>
                <span>
                  {sendType === "EMAIL"
                    ? `Insira o código enviado no email ${state?.email}`
                    : `Insira o código enviado por SMS no seu celular: ${
                        hidePhone(phoneMask(state?.phone)) || ""
                    }`}
                </span>
                <S.ContentInput>
                  <Input
                    type="text"
                    inputmode="numeric"
                    label="Código de verificação"
                    placeholder="000 000"
                    maxLength={7}
                    value={verificationCode}
                    onChange={(e) =>
                      setVerificationCode(verificationCodeMask(e.target.value))
                    }
                  />
                  <S.ResendCode opacity={chronometer !== 0}>
                    <span>
                      {`Não recebeu o ${sendType}? Você pode`}
                      <span
                        class={
                          chronometer !== 0
                            ? "unclickable-span"
                            : "clickable-span"
                        }
                        onClick={reSendSMS}
                      >
                        {" "}
                        reenviar
                      </span>
                      .{chronometer !== 60 && ` Aguarde ${chronometer}.`}
                    </span>
                  </S.ResendCode>
                </S.ContentInput>
                <S.ContentButton>
                  <Button
                    disabled={handleDisabledButton()}
                    onClick={() => handleClickButton()}
                  >
                    Continuar
                  </Button>
                </S.ContentButton>
                {count >= 3 && (
                  <S.Message>
                    <span>
                      Caso não esteja recebendo o codigo
                      <span
                        class={
                          chronometer !== 0
                            ? "unclickable-span"
                            : "clickable-span"
                        }
                        onClick={() => {if (chronometer === 0) setModalIsOpen(true)}}
                      >
                        {" "}
                        clique aqui para escolher outra forma.
                      </span>
                    </span>
                  </S.Message>
                )}
              </S.Main>
            )}
          </>
        )}
      </S.Content>
    </Layout>
  );
};

export default ValidadeCode;
