import React from 'react';
import { Container } from './styles';
import themes from '~/themes';
import { Link } from 'react-router-dom';

const WhatsApp = (props) => {
  const handleClick = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=559240033203&text=Preciso de ajuda com meu TeuCard.',
      '_blank'
    );
  };

  return (
    <Container onClick={handleClick}>
      <p>
        <Link onClick={() => handleClick()} title="Atendimento no WhatsApp">
          {themes.components.icons.whatsApp}
        </Link>
      </p>
    </Container>
  );
};

export default WhatsApp;
