import { useState } from "react";
import Repository from "./repository";

export const ApiMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

function useApi(url, method, body = {}, headers = {}) {
  const [responseApi, setResponseApi] = useState({
    status: null,
    loading: false,
    error: null,
    data: null,
    success: false,
    response: null,
    mensagem: null,
  });

  const callApi = (reqBody = {}) => FetchData(reqBody);

  const getOptions = (reqBody = {}) => {
    const reqHeaders = new Headers();
    const token = Repository.storage.get("@NovaEra:token");
    const tokenBearer = Repository.storage.get("@NovaEra:tokenBearer");
    const xCode = Repository.session.get("@NovaEra:xCode");
    const faceID = Repository.session.get("@NovaEra:faceID");
    const xOrigenKey = Repository.session.get("@NovaEra:X-Origin-Key");

    reqHeaders.set("Content-Type", "application/json");
    if (token) reqHeaders.set("apikey", `token ${token}`);
    if (tokenBearer) reqHeaders.set("Authorization", `Bearer ${tokenBearer}`);
    if (xCode) reqHeaders.set("xcode", xCode);
    if(faceID) reqHeaders.set("x-code", faceID);
    if(xOrigenKey) reqHeaders.set("X-Origin-Key", xOrigenKey);

    
    if (headers) Object.assign(reqHeaders, headers);

    let newBody = reqBody || body;

    if (!Array.isArray(body) && !Array.isArray(reqBody)) {
      newBody = Object.assign(body, reqBody);
    }

    let options = {
      method,
      headers: reqHeaders,
    };

    if (method !== ApiMethod.GET) {
      options = {
        ...options,
        body: JSON.stringify(newBody) || null,
      };
    }

    return options;
  };

  const FetchData = async (reqBody = {}) => {
    setResponseApi({ ...responseApi, loading: true });

    const options = getOptions(reqBody);
    let copyResponse;
    let json = null;

    try {
      const res = await fetch(url, options);
      copyResponse = res.clone();
      const statusResponse = res.status;

      if (res.status === 401 || res.status === 403) {
        localStorage.removeItem("@NovaEra:user");
        localStorage.removeItem("@NovaEra:token");
        localStorage.removeItem("@NovaEra:tokenBearer");
        localStorage.removeItem("@NovaEra:tokenCartao");
        sessionStorage.removeItem("@NovaEra:xCode");
      }

      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        json = await res.json();
      }

      setResponseApi({
        loading: false,
        data: json,
        error: null,
        status: statusResponse,
        success: true,
        response: res,
        mensagem: json ? json.mensagem : "",
      });
    } catch (err) {
      setResponseApi({
        loading: false,
        data: null,
        error: err.message,
        status: copyResponse.status,
        success: false,
        response: null,
        mensagem: null,
      });
    }

    return {
      status: copyResponse.status,
      data: json,
      response: copyResponse,
      mensagem: json ? json.mensagem : "",
      headers: copyResponse.headers,
    };
  };

  return { callApi, ...responseApi };
}

export default useApi;
