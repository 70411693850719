import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Welcome from "~/views/Welcome";
import InputCPF from "~/views/NoAuthInvoice/InputCPF";
import LoadingCPF from "~/views/NoAuthInvoice/LoadingCPF";
import ValidateCode from "~/views/NoAuthInvoice/ValidateCode";
import Register from "~/views/Register";
import PushSMSBlock from "~/views/PushSMSBlock";
import Login from "~/views/Login";
import Home from "~/views/Home";
import ClientHome from "~/views/ClientHome";
import Transactions from "~/views/Transactions";
import Profile from "~/views/Profile";
import CancelAccount from "~/views/Profile/screens/cancelAccount";
import CardMain from "~/views/Card";
import CodeVerification from "~/views/Card/CodeVerification";
import BlockReason from "~/views/Card/BlockReason";
import InfoPasswordRedefinition from "~/views/Card/InfoPasswordRedefinition";
import FormPasswordRedefinition from "~/views/Card/FormPasswordRedefinition";
import FormSendEmail from "~/views/Card/FormSendEmail";
import DuplicateInvoice from "~/views/Card/DuplicateInvoice";
import ConsultInvoices from "~/views/Card/ConsultInvoices";
import ContactUs from "~/views/ContactUs";
import Safety from "~/views/Security";
import NewPassword from "~/views/NewPassword";
import ForgotPassword from "~/views/ForgotPassword";
import HomeProdutos from "~/views/HomeProdutos";
import { useAuth } from "~/hooks/auth";
import { toggleChatbot } from "~/utils/chatbot";
import Document from "~/views/documents";
import Terms from "~/views/Profile/screens/Terms";
import Contract from "~/views/Profile/screens/Contract";
import LoanContractedWithdraw from "~/views/documents/screens/LoanContractedWithdraw";
import ViewWithdraw from "~/views/documents/screens/ViewWithdraw";
import LoanVoucherAndContracted from "~/views/documents/screens/LoanVoucherAndContracted";
import PrivacyPolicy from "~/views/Profile/screens/Privacy";
import UserPersonalData from "~/views/DigitalWallet/UserPersonalData";
import VerificationCode from "~/views/DigitalWallet/VerificationCode";
import UserPersonalDataMain from "~/views/DigitalWallet/UserPersonalDataMain";
import CreatePassword from "~/views/DigitalWallet/CreatePassword";
import FirstScreenOnboarding from "~/views/DigitalWallet/FirstScreenOnboarding";
import SearchCpf from "~/views/DigitalWallet/SearchCpf";
import AlreadyClient from "~/views/DigitalWallet/AlreadyClient";
import VerificationCodeIfExists from "~/views/DigitalWallet/VerificationCodeIfExists";
import SuccessValidation from "~/views/DigitalWallet/SuccessValidation";
import CarteiraDigitalHome from "~/views/DigitalWallet/Home";
import CarteiraDigitalDocuments from "~/views/DigitalWallet/Documents";
import AddCreditCard from "~/views/DigitalWallet/AddCreditCard";
import DigitalWalletTransations from "~/views/DigitalWallet/Transation";
import DigitalWalletContract from "~/views/DigitalWallet/Contract";
import DigitalWalletTerms from "~/views/DigitalWallet/Terms";
import PaymentVoucher from "~/views/DigitalWallet/Transation/PaymentVoucher";
import AddCreditCardVerificationCode from "~/views/DigitalWallet/addCreditCardVerificationCode";
import FirstScreenWelcome from "~/views/Lending/screens/FirstScreenWelcome";
import PersonalData from "~/views/Lending/screens/PersonalData";
import SimulationType from "~/views/Lending/screens/SimulationType";
import Simulation from "~/views/Lending/screens/Simulation";
import ResultSimulation from "~/views/Lending/screens/ResultSimulation";
import PersonalDataMain from "~/views/Lending/screens/PersonalDataMain";
import BankingData from "~/views/Lending/screens/BankingData";
import LeadsEmprestimo from "~/views/Lending/screens/Leads";
import LendingSuccess from "~/views/Lending/screens/Success";
import LendingType from "~/views/LendingTeuCard/screens/LendingType";
import WelcomeLending from "~/views/LendingTeuCard/screens/WelcomeLending";
import ConfirmData from "~/views/LendingTeuCard/screens/ConfirmData";
import LeadsWithdraw from "~/views/LendingTeuCard/screens/LeadsWithdraw";
import SimulationWithdraw from "~/views/LendingTeuCard/screens/SimulationWithdraw";
import ResultSimulationWithdraw from "~/views/LendingTeuCard/screens/ResultSimulationWithdrawal";
import CancelWithdraw from "~/views/LendingTeuCard/screens/LendingCancelWithdraw";
import AdditionalData from "~/views/LendingTeuCard/screens/AdditionalData";
import BankingWithdraw from "~/views/LendingTeuCard/screens/BankingWithdraw";
import TermsLending from "~/views/LendingTeuCard/screens/TermsLending";
import AgreementLending from "~/views/LendingTeuCard/screens/Agreement";
import VerificationPhoto from "~/views/LendingTeuCard/screens/VerificationPhoto";
import SelfieSignature from "~/views/LendingTeuCard/screens/SelfieSignature";
import ErrorLending from "~/views/LendingTeuCard/screens/Error";
import SuccessWithdraw from "~/views/LendingTeuCard/screens/Success";
import LinkSMS from "~/views/hyperLink";
import QrCodePix from "~/views/qrCodePix";
import InfoLending from "~/views/Lending/screens/infoLending";
import WelcomeByLendingSearch from "~/views/LendingBySearch/screens/welcomeByLendingSearch";
import ConfirmDataByLendingSearch from "~/views/LendingBySearch/screens/confirmDataByLendingSearch";
import AdditionalDataByLendingSearch from "~/views/LendingBySearch/screens/additionalDataByLendingSearch";
import ComplementaryDataByLendingSearch from "~/views/LendingBySearch/screens/complementaryDataByLendingSearch";
import LoanHome from "~/views/LoanHome";
import IdentifyDataByLendingSearch from "~/views/LendingBySearch/screens/identifyDataByLendingSearch";
import ResidentialDataByLendingSearch from "~/views/LendingBySearch/screens/residentialDataByLendingSearch";
import BankingDataByLendingSearch from "~/views/LendingBySearch/screens/bankingDataByLendingSearch";
import SimulationByLendingSearch from "~/views/LendingBySearch/screens/simulationByLendingSearch";
import SearchProposalByLendingSearch from "~/views/LendingBySearch/screens/searchProposalByLendingSearch";
import LendingExtractByTeuCard from "~/views/LoanHome/loanByTeuCard";
import LendingExtractByLoanSearch from "~/views/LoanHome/loanBySearch";
import SubmitDocuments from "~/views/LendingBySearch/uploadDocuments/proofOfIncome";
import WithDrawKYCValidation from "~/views/LendingTeuCard/screens/WithDrawKycValidation";
import WhatsApp from "~/components/WhatsApp";

const Router = (props) => {
  const { isLogged } = useAuth();

  useEffect(() => {
    toggleChatbot(isLogged);
  }, [isLogged]);

  return (
    <BrowserRouter>
      {isLogged ? (
        <Switch>
          <Route path="/home" component={ClientHome} />
          <Route
            path="/carteira-digital-home"
            component={CarteiraDigitalHome}
          />
          <Route
            path="/carteira-digital-documents"
            component={CarteiraDigitalDocuments}
          />
          <Route path="/add-credit-card" component={AddCreditCard} />
          <Route
            path="/add-credit-card-verification-code"
            component={AddCreditCardVerificationCode}
          />
          <Route path="/cartoes-teuCard-detalhes" component={Home} />
          <Route path="/cartao-fatura" component={DuplicateInvoice} />
          <Route path="/consultar-faturas" component={ConsultInvoices} />
          <Route path="/transacoes" component={Transactions} />
          <Route
            path="/carteira-digital-transacoes"
            component={DigitalWalletTransations}
          />
          <Route
            path="/carteira-digital-payment-voucher"
            component={PaymentVoucher}
          />
          <Route path="/carteira-digita-terms" component={DigitalWalletTerms} />
          <Route
            path="/carteira-digital-contract"
            component={DigitalWalletContract}
          />
          <Route path="/perfil" component={Profile} />
          <Route path="/Cancelar-conta" component={CancelAccount} />
          <Route path="/cartao-main" component={CardMain} />
          <Route exact path="/LinkSMS" component={LinkSMS} />
          <Route
            path="/cartao-codigo-verificacao"
            component={CodeVerification}
          />
          <Route path="/cartao-motivo-bloqueio" component={BlockReason} />
          <Route path="/pushSMSBlock" component={PushSMSBlock} />
          <Route
            path="/cartao-info-redefinir-senha"
            component={InfoPasswordRedefinition}
          />
          <Route
            path="/cartao-form-redefinir-senha"
            component={FormPasswordRedefinition}
          />
          <Route path="/cartao-enviar-email" component={FormSendEmail} />
          <Route path="/fale-conosco" component={ContactUs} />
          <Route path="/safety" component={Safety} />
          <Route path="/document" component={Document} />
          <Route path="/terms" component={Terms} />
          <Route path="/contract" component={Contract} />
          <Route path="/contract-withdraw" component={LoanContractedWithdraw} />
          <Route path="/view-contract" component={ViewWithdraw} />
          <Route
            path="/contract-voucher"
            component={LoanVoucherAndContracted}
          />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/qrcode-pix" component={QrCodePix} />
          <Route
            exact
            path="/onboarding-digital-wallet"
            component={FirstScreenOnboarding}
          />
          <Route exact path="/emprestimo" component={FirstScreenWelcome} />
          <Route path="/emprestimo/dados-pessoais" component={PersonalData} />
          <Route path="/emprestimo/tipo-simulacao" component={SimulationType} />
          <Route path="/emprestimo/simulacao" component={Simulation} />
          <Route
            path="/emprestimo/resultado-simulacao"
            component={ResultSimulation}
          />
          <Route
            path="/emprestimo/dados-adicionais"
            component={PersonalDataMain}
          />
          <Route path="/emprestimo/dados-bancarios" component={BankingData} />
          <Route
            path="/emprestimo/teucard"
            component={LendingExtractByTeuCard}
          />
          <Route path="/emprestimo/leads" component={LeadsEmprestimo} />
          <Route path="/emprestimo/successo" component={LendingSuccess} />
          <Route
            exact
            path="/emprestimo/info-onboard"
            component={InfoLending}
          />
          <Route
            exact
            path="/emprestimo/escolher-tipo"
            component={LendingType}
          />
          {/*Buscador de crédito*/}
          <Route exact path="/loan-home" component={LoanHome} />
          <Route exact path="/buscador" component={WelcomeByLendingSearch} />
          <Route
            exact
            path="/buscador/confirmar-dados"
            component={ConfirmDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/dados-adicionais"
            component={AdditionalDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/dados-complementares"
            component={ComplementaryDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/dados-identificacao"
            component={IdentifyDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/dados-residenciais"
            component={ResidentialDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/dados-bancarios"
            component={BankingDataByLendingSearch}
          />
          <Route
            exact
            path="/buscador/simular"
            component={SimulationByLendingSearch}
          />
          <Route
            exact
            path="/buscador/buscando-propostas"
            component={SearchProposalByLendingSearch}
          />
          <Route
            path="/buscador/easyCredito"
            component={LendingExtractByLoanSearch}
          />
          <Route
            path="/loan-home/submeter-documentos"
            component={SubmitDocuments}
          />
          {/*Empréstimo por cartão de crédito*/}
          <Route
            exact
            path="/emprestimo/bem-vindo"
            component={WelcomeLending}
          />
          <Route
            exact
            path="/emprestimo/confirmar-dados"
            component={ConfirmData}
          />
          <Route
            exact
            path="/emprestimo/leads-saque"
            component={LeadsWithdraw}
          />
          <Route
            exact
            path="/emprestimo/simulacao-saque"
            component={SimulationWithdraw}
          />
          <Route
            exact
            path="/emprestimo/resultado-saque"
            component={ResultSimulationWithdraw}
          />
          <Route
            exact
            path="/emprestimo/cancelar-saque"
            component={CancelWithdraw}
          />
          <Route
            exact
            path="/emprestimo/dados-adicionais-saque"
            component={AdditionalData}
          />
          <Route
            exact
            path="/emprestimo/dados-bancarios-saque"
            component={BankingWithdraw}
          />
          <Route
            exact
            path="/emprestimo/validacao-cliente"
            component={WithDrawKYCValidation}
          />
          <Route exact path="/emprestimo/termos" component={TermsLending} />
          <Route exact path="/emprestimo/aceite" component={AgreementLending} />
          <Route
            exact
            path="/emprestimo/verificacao-foto"
            component={VerificationPhoto}
          />
          <Route
            exact
            path="/emprestimo/assinatura"
            component={SelfieSignature}
          />
          <Route exact path="/emprestimo/erro" component={ErrorLending} />
          <Route
            exact
            path="/emprestimo/sucesso-saque"
            component={SuccessWithdraw}
          />
          <Route
            exact
            path="/onboarding-digital-wallet"
            component={FirstScreenOnboarding}
          />
          <Route exact path="/onboarding-validar-cpf" component={SearchCpf} />
          <Route
            exact
            path="/onboarding-validar-dados"
            component={UserPersonalData}
          />
          <Route
            exact
            path="/onboarding-validar-sms"
            component={VerificationCode}
          />
          <Route
            exact
            path="/onboarding-validar-dados-main"
            component={UserPersonalDataMain}
          />
          <Route
            exact
            path="/onboarding-criar-senha"
            component={CreatePassword}
          />
          <Route
            exact
            path="/onboarding-cliente-existente"
            component={AlreadyClient}
          />
          <Route
            exact
            path="/onboarding-validar-sms-cliente-existente"
            component={VerificationCodeIfExists}
          />
          <Route
            exact
            path="/onboarding-validacao-sucesso"
            component={SuccessValidation}
          />
          <Redirect to="/home" />
        </Switch>
      ) : (
        <Switch>
          {/* {!wasAccessed && <Route exact path="/" component={Welcome} />} */}
          <Route exact path="/" component={Welcome} />
          <Route exact path="/LinkSMS" component={LinkSMS} />
          <Route exact path="/Fatura-CPF" component={InputCPF} />
          <Route exact path="/Fatura-Loading" component={LoadingCPF} />
          <Route exact path="/Fatura-Validate" component={ValidateCode} />
          <Route exact path="/cadastro" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/NewPassword" component={NewPassword} />
          <Route exact path="/home-produtos" component={HomeProdutos} />
          <Route
            exact
            path="/emprestimo/info-onboard"
            component={InfoLending}
          />
          <Route
            exact
            path="/onboarding-digital-wallet"
            component={FirstScreenOnboarding}
          />
          <Route exact path="/onboarding-validar-cpf" component={SearchCpf} />
          <Route
            exact
            path="/onboarding-validar-dados"
            component={UserPersonalData}
          />
          <Route
            exact
            path="/onboarding-validar-sms"
            component={VerificationCode}
          />
          <Route
            exact
            path="/onboarding-validar-dados-main"
            component={UserPersonalDataMain}
          />
          <Route
            exact
            path="/onboarding-criar-senha"
            component={CreatePassword}
          />
          <Route
            exact
            path="/onboarding-cliente-existente"
            component={AlreadyClient}
          />
          <Route
            exact
            path="/onboarding-validar-sms-cliente-existente"
            component={VerificationCodeIfExists}
          />
          <Route
            exact
            path="/onboarding-validacao-sucesso"
            component={SuccessValidation}
          />
          <Route
            exact
            path="/onboarding-validar-dados"
            component={UserPersonalData}
          />
          <Route
            exact
            path="/onboarding-validar-sms"
            component={VerificationCode}
          />
          <Route
            exact
            path="/onboarding-validar-dados-main"
            component={UserPersonalDataMain}
          />
          <Route
            exact
            path="/onboarding-criar-senha"
            component={CreatePassword}
          />
          <Route
            exact
            path="/onboarding-cliente-existente"
            component={AlreadyClient}
          />
          <Route
            exact
            path="/onboarding-validar-sms-cliente-existente"
            component={VerificationCodeIfExists}
          />
          <Route
            exact
            path="/onboarding-validacao-sucesso"
            component={SuccessValidation}
          />
          <Route exact path="/LinkSMS" component={LinkSMS} />

          {/* <Route path="/login" component={Login} /> */}
          <Redirect from="/" to="/" />
          <Redirect to="/" />
        </Switch>
      )}
      {/* Whatsapp icon when is not logged*/}
      {!isLogged && (
        <div>
          <WhatsApp />
        </div>

      )}
    </BrowserRouter>
  );
};

export default Router;
