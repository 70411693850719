import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 10px; 
  right: 20px;  
  transform: translate(0, 0);
  z-index: 1000;
  transition: opacity 0.3s ease;

  &.hidden {
    display: none;
  }

  svg {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
`;
